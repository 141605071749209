import React, { useEffect, useContext } from "react";
import { graphql, Link } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import "katex/dist/katex.min.css";

import Layout from "../components/layout";
import NextPrevButtons from "../components/NextPrevButtons";
import { lessonDispatchContext } from "../context/lessonsContext";
import ArticlesAds from "components/ArticlesAds";
import LazyExerciseSolution from "components/LazyExerciseSolution";
import ExerciseTitle from "components/ExerciseTitle";
import Loader from "components/loader";
import PrintingHeader from "components/PrintingHeader";

export const createSlug = (
  title,
  level,
  division,
  type,
  unite,
  postType,
  replacement = "-"
) => {
  const getUrlFriendlyStr = str => {
    return str.trim().replace(/\s/g, replacement);
  };
  if (type === "مستوى") {
    return `/${getUrlFriendlyStr(title)}`;
  }
  if (type === "وحدة") {
    return `/${getUrlFriendlyStr(title)}/${getUrlFriendlyStr(
      division
    )}-${getUrlFriendlyStr(level)}`;
  }
  if (type === "post") {
    return `/${getUrlFriendlyStr(title)}/${getUrlFriendlyStr(postType)}`;
  }

  return `/${getUrlFriendlyStr(title)}/${getUrlFriendlyStr(
    unite
  )}/${getUrlFriendlyStr(division)}-${getUrlFriendlyStr(level)}`;
};

const LessonTemplate = ({
  banner,
  next,
  previous,
  content,
  printInfo,
  exos,
  location,
}) => {
  return (
    <>
      <header className={`banner banner-${banner}`}>
        <div className="content">
          <h2 style={{ color: "white" }}>دروس خصوصية مع الأستاذ بن أحمد</h2>
          <p className="description">
            لكل مستويات الطور الثانوي في مادة العلوم الفيزيائية
          </p>
          <Link className="button primary" to="/دروس-خصوصية">
            إقرأ المزيد
          </Link>
        </div>
      </header>
      <NextPrevButtons next={next} prev={previous} />

      {exos.map(exo => (
        <>
          <ExerciseTitle
            title="تمرين"
            rank={exo.rank}
            difficulty={exo.difficulty}
            key={exo.rank}
          />
          <MDXRenderer
            printInfo={{ ...exo, lessonType: printInfo.lessonType }}
            key={exo.rank + 1}
          >
            {exo.content}
          </MDXRenderer>
          <div className="print">
            <LazyExerciseSolution
              autoShow={false}
              printInfo={exo}
              location={location}
              key={exo.rank + 2}
            />
          </div>
        </>
      ))}
      <PrintingHeader />
      <div className="screen">
        {exos.map(exo => (
          <>
            <ExerciseTitle
              title="حل التمرين"
              rank={exo.rank}
              difficulty={exo.difficulty}
              key={exo.rank}
            />
            <LazyExerciseSolution
              autoShow={true}
              printInfo={exo}
              location={location}
              key={exo.rank + 2}
            />
          </>
        ))}
      </div>
      <ArticlesAds
        to={`${createSlug(
          `${printInfo.division} ${printInfo.level}`,
          null,
          null,
          "مستوى"
        )}#exercises`}
      />
      <NextPrevButtons next={next} prev={previous} />
    </>
  );
};
const Series = ({ data, pageContext, location }) => {
  if (data.allFile && typeof window === "object") {
    window.location.reload();
    return <Loader />;
  }
  const { title } = pageContext;
  const setLessonDetails = useContext(lessonDispatchContext);
  const getLessonInfo = (data, title) => {
    const {
      node: {
        id,
        childMdx: { frontmatter, body },
      },
      next,
      previous,
    } = data.edges.filter(
      ({ node }) => node.childMdx.frontmatter.title === title
    )[0];
    return {
      next: next && {
        title: next.childMdx.frontmatter.title,
        slug: next.childMdx.fields.slug,
      },
      previous: previous && {
        title: previous.childMdx.frontmatter.title,
        slug: previous.childMdx.fields.slug,
      },
      printInfo: {
        id,
        title: frontmatter.title,
        lessonType: frontmatter.lessonType,
        level: frontmatter.level,
        unite: frontmatter.unite,
        domain: frontmatter.domain,
        division: frontmatter.division,
      },
      course: {
        banner: frontmatter.banner,
        thumbnail: frontmatter.thumbnail.childImageSharp.fluid,
        body,
        description: frontmatter.description,
      },
    };
  };
  const getExercisesInfo = data => {
    return data.edges.map(d => {
      const {
        node: {
          childMdx: { frontmatter, body },
        },
      } = d;
      return {
        title: frontmatter.title,
        unite: frontmatter.unite,
        exoId: frontmatter.exoId,
        level: frontmatter.level,
        division: frontmatter.division,
        difficulty: frontmatter.difficulty,
        authorId: frontmatter.author,
        rank: frontmatter.lessonRank,
        ads: false,
        content: body,
      };
    });
  };
  const { course, next, previous, printInfo } = getLessonInfo(
    data.series,
    title
  );
  const exercises = getExercisesInfo(data.exos);

  const { banner, thumbnail, body, description } = course;
  useEffect(() => {
    setLessonDetails(state => ({ ...state, lessonInfo: printInfo }));
  }, [printInfo]);
  return (
    <Layout
      title={title}
      description={description}
      classTitle="main"
      classSection="serie"
      image={thumbnail}
      location={location}
    >
      <LessonTemplate
        banner={banner}
        next={next}
        previous={previous}
        content={body}
        printInfo={printInfo}
        location={location}
        exos={exercises}
      />
    </Layout>
  );
};

export default Series;

export const query = graphql`
  query($level: String!, $division: String!, $seriesId: String!) {
    series: allFile(
      filter: {
        sourceInstanceName: { eq: "series" }
        childMdx: {
          frontmatter: {
            level: { eq: $level }
            division: { eq: $division }
            lessonType: { eq: "سلسلة" }
          }
        }
      }
      sort: { fields: childMdx___frontmatter___rank }
    ) {
      edges {
        node {
          id
          childMdx {
            fields {
              slug
            }
            frontmatter {
              title
              domain
              lessonType
              unite
              division
              level
              description
              banner
              thumbnail {
                childImageSharp {
                  fluid(maxWidth: 75, quality: 70) {
                    ...GatsbyImageSharpFluid_withWebp
                    originalName
                  }
                }
              }
            }
            body
            excerpt
          }
        }
        next {
          childMdx {
            fields {
              slug
            }
            frontmatter {
              title
            }
          }
        }
        previous {
          childMdx {
            fields {
              slug
            }
            frontmatter {
              title
            }
          }
        }
      }
    }
    exos: allFile(
      filter: {
        sourceInstanceName: { eq: "exercises" }
        childMdx: {
          frontmatter: {
            level: { eq: $level }
            division: { eq: $division }
            serieId: { eq: $seriesId }
          }
        }
      }
      sort: { fields: childMdx___frontmatter___serieRank }
    ) {
      edges {
        node {
          childMdx {
            frontmatter {
              author
              exoId
              lessonRank
              division
              level
              difficulty
            }
            body
          }
        }
      }
    }
  }
`;
